import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.jsx";
import "./index.css";

createRoot(document.getElementById("root")).render(
  <StrictMode>
    <div id="stars" />
    <div id="stars2" />
    <div id="stars3" />
    <App />
  </StrictMode>,
);
