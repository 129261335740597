import "./titleText.css";
export default function TitleText() {
  return (
    <div className="text">
      <div>
        J<div>J</div>
        <div>J</div>
        <div>J</div>
        <div>J</div>
        <div>J</div>
        <div>J</div>
        <div>J</div>
        <div>J</div>
        <div>J</div>
        <div>J</div>
      </div>
      <div>
        u<div>u</div>
        <div>u</div>
        <div>u</div>
        <div>u</div>
        <div>u</div>
        <div>u</div>
        <div>u</div>
        <div>u</div>
        <div>u</div>
        <div>u</div>
      </div>
      <div>
        n<div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
      </div>
      <div>
        M<div>M</div>
        <div>M</div>
        <div>M</div>
        <div>M</div>
        <div>M</div>
        <div>M</div>
        <div>M</div>
        <div>M</div>
        <div>M</div>
        <div>M</div>
      </div>
      <div>
        a<div>a</div>
        <div>a</div>
        <div>a</div>
        <div>a</div>
        <div>a</div>
        <div>a</div>
        <div>a</div>
        <div>a</div>
        <div>a</div>
        <div>a</div>
      </div>
      <div>
        n<div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
        <div>n</div>
      </div>
      <div>
        g<div>g</div>
        <div>g</div>
        <div>g</div>
        <div>g</div>
        <div>g</div>
        <div>g</div>
        <div>g</div>
        <div>g</div>
        <div>g</div>
        <div>g</div>
      </div>
      <div>
        o<div>o</div>
        <div>o</div>
        <div>o</div>
        <div>o</div>
        <div>o</div>
        <div>o</div>
        <div>o</div>
        <div>o</div>
        <div>o</div>
        <div>o</div>
      </div>
    </div>
  );
}
